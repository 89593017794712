import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query'
import { Mutex } from 'async-mutex'
import { RootState } from 'store'
import { updateToken } from 'store/slices'

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT
const appBaseUrl = process.env.REACT_APP_PROJECT_ENDPOINT
const mutex = new Mutex()

// used to get administration info after login
export const baseQueryAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: appBaseUrl,
    prepareHeaders: headers => {
      const token = (appApi.getState() as RootState).authState.token
      if (token.length) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })
  return rawBaseQuery(args, appApi, extraOptions)
}

// used to login and get the bearer token
export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({ baseUrl })
  return rawBaseQuery(args, appApi, extraOptions)
}

// Apply a custom fetch function to the baseQuery to intercept invalid token responses
export const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQueryAuth(args, api, extraOptions)

  if (
    (result.error?.data as any)?.message === 'You are not logged in' ||
    [401, 403, 500].includes(result.error?.status as number)
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQueryAuth(
          {
            url: `${baseUrl}Authenticate/Get`,
            method: 'GET',
          },
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          const payload = refreshResult.data as string[]
          api.dispatch(updateToken(payload[0]))
          result = await baseQuery(args, api, extraOptions)
        } else {
          window.location.href = '/logout'
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
