import { useToast } from '@icr-ui/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface UseResponseHandlersProps {
  isLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  error?: {
    [key: string]: any
  }
  isLoadingEdit?: boolean
  isSuccessEdit?: boolean
  isErrorEdit?: boolean
  errorEdit?: any
  successMessage: string
  successMessageEdit?: string
  goBack?: boolean
  onConfirm?: () => void
}

const useResponseHandlers = ({
  isLoading,
  isLoadingEdit,
  isSuccess,
  isSuccessEdit,
  successMessage,
  error,
  errorEdit,
  isError,
  isErrorEdit,
  successMessageEdit,
  goBack = true,
  onConfirm,
}: UseResponseHandlersProps) => {
  const { message } = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      message.success(successMessage)
      onConfirm && onConfirm()
      goBack && navigate(-1)
    }
    if (isError && error) {
      if (Array.isArray((error as any).data.errors)) {
        message.error((error as any).data.errors[0].detail)
      } else {
        message.error((error as any).data.detail)
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (isSuccessEdit && successMessageEdit) {
      message.success(successMessageEdit)
      navigate(-1)
    }
    if (isErrorEdit) {
      if (Array.isArray((errorEdit as any).data.errors)) {
        ;(errorEdit as any).data.errors.forEach((el: any) => message.error(el.detail))
      } else {
        message.error((errorEdit as any).data.detail)
      }
    }
  }, [isLoadingEdit])

  return {}
}

export default useResponseHandlers
