import { CardSkeleton, Col, Container, Paper, Row } from '@icr-ui/core'
import { useMediaQuery } from '@icr-ui/hooks'

const SelectDataSkeleton = () => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  const array = Array.from(Array(6).keys()).map(i => i + 1)

  const propsSecondRow = {
    content: [
      {
        columnWidth: [3, 3, 3, 3],
        columnProps: ['100%', '50%'],
      },
      {
        columnWidth: [5, 5, 5, 5],
        columnProps: ['0%'],
      },
      {
        columnWidth: [1, 1, 1, 1],
        columnProps: ['100%'],
      },
      {
        columnWidth: [1, 1, 1, 1],
        columnProps: ['100%'],
      },
      {
        columnWidth: [1, 1, 1, 1],
        columnProps: ['100%'],
      },
      {
        columnWidth: [1, 1, 1, 1],
        columnProps: ['100%'],
      },
    ],
  }

  const mobileProps = {
    content: [
      {
        columnWidth: [6, 6, 12, 12],
        columnProps: ['100%', '28.17%'],
      },
    ],
  }
  return (
    <Container customStyles={{ paddingTop: '0 !important' }}>
      <Row>
        {array.map(item => (
          <Col key={item} xs={12} sm={6} md={6} lg={6}>
            <Paper
              fullWidth
              height="auto"
              withHover
              customStyles={{
                overflow: 'hidden',
                cursor: 'pointer',

                padding: isSmallScreen ? '24px' : '24px 32px',
                marginBottom: '16px',
              }}
            >
              {isSmallScreen ? (
                <CardSkeleton {...mobileProps} />
              ) : (
                <>
                  <CardSkeleton {...propsSecondRow} />
                </>
              )}
            </Paper>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default SelectDataSkeleton
