import { IComponentResponse } from 'store/types'
import { protectedApi } from '../api'

export const componentApi = protectedApi.injectEndpoints({
  endpoints: builder => ({
    getComponents: builder.query<IComponentResponse['data'], string>({
      query(pageCode) {
        return {
          url: `Object/ComponentSelect/${pageCode}`,
          method: 'GET',
        }
      },
      transformResponse: (response: IComponentResponse) => {
        const { data } = response
        return data
      },
    }),
  }),
})

export const { useGetComponentsQuery } = componentApi
