import { useAppSelector } from 'store'

const useAddLanguage = () => {
  const {
    appState: { currentTenant },
    contentState: { availableContent },
    enumeratorState: { languages },
  } = useAppSelector(state => state)

  const allowedLanguages = [
    {
      tenant: 'VISANA',
      languageIds: [1, 2],
    },
  ]

  const currentTenantAllowedLanguageIds =
    allowedLanguages.find(item => item.tenant === currentTenant)?.languageIds || []

  const existingLanguagesIds =
    availableContent?.attributes.componentAttributes.contents?.map(item =>
      Number(item.language),
    )

  const missingLanguageIds = currentTenantAllowedLanguageIds.filter(
    id => !existingLanguagesIds?.includes(id),
  )

  const addLanguageList = missingLanguageIds?.map(id => ({
    id,
    label: languages.find(item => Number(item.value) === id)?.label,
  }))

  return {
    addLanguageList,
  }
}

export default useAddLanguage
