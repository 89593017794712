import { Button, Col, Row, TabProps, Tabs } from '@icr-ui/core'
import { ListMenu } from 'components/list-menu'
import { useAddLanguage } from 'hooks'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppDispatch, useAppSelector } from 'store'
import { useUpdateContentMutation } from 'store/api/translation/contentApi'
import {
  setAvailableContent,
  setConfirmationModalOpen,
  setCurrentLanguage,
} from 'store/slices'
import { IContents } from 'store/types'

interface LanguageSelectorProps {
  contentsData: IContents[] | undefined
}

const LanguageSelector = ({ contentsData }: LanguageSelectorProps) => {
  const { pageID, componentID, languageID } = useParams<{
    pageID: string
    componentID: string
    languageID: string
  }>()
  const navigate = useNavigate()
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)

  const dispatch = useAppDispatch()
  const {
    contentState: { availableContent, isEditorDataChanged },
    enumeratorState: { languages },
  } = useAppSelector(state => state)
  const { addLanguageList } = useAddLanguage()

  const enableAddLanguageButton = !!addLanguageList.length

  const [updateContent] = useUpdateContentMutation()

  const handleChangeLanguage = (e: number) => {
    dispatch(setCurrentLanguage(e.toString()))
    if (isEditorDataChanged) {
      dispatch(setConfirmationModalOpen(true))
    } else {
      navigate(
        DefaultRoutes.SELECT_CONTENT.replace(':pageID', pageID as string)
          .replace(':componentID', componentID as string)
          .replace(':languageID', e.toString()),
      )
    }
  }

  const handleAddLanguage = ({ label, id }: any) => {
    if (availableContent) {
      const newContent = {
        ...availableContent,
        attributes: {
          ...availableContent.attributes,
          componentAttributes: {
            ...availableContent.attributes.componentAttributes,
            contents: [
              ...availableContent.attributes.componentAttributes.contents,
              {
                ...availableContent.attributes.componentAttributes.contents[0],
                id,
                language: id,
                name: label,
              },
            ],
          },
        },
      }

      handleChangeLanguage(id)

      dispatch(setAvailableContent(newContent))

      updateContent({
        data: newContent,
        pageId: pageID as string,
        componentId: componentID as string,
      })
    }
  }

  const toggleLanguageMenu = () => setIsLanguageMenuOpen(!isLanguageMenuOpen)

  const addLanguageListOptions = addLanguageList.map(({ id, label }) => ({
    id,
    label,
    callback: handleAddLanguage,
  }))

  return (
    <Row>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        flexDirection="row"
        gap="3rem"
        justifyContent="space-between"
      >
        {contentsData && (
          <>
            <Tabs
              tabs={
                contentsData.map(content => {
                  return {
                    label: languages.find(
                      lang => lang.value === content.language.toString(),
                    )?.label,
                  }
                }) as TabProps[]
              }
              setSelectedTab={(e: number) => handleChangeLanguage(e + 1)}
              activeTab={parseInt(languageID as string) - 1}
            />

            <ListMenu
              isOpen={isLanguageMenuOpen}
              setIsOpen={setIsLanguageMenuOpen}
              options={addLanguageListOptions}
            >
              <Button
                disabled={!enableAddLanguageButton}
                variant="outlined"
                small
                {...(enableAddLanguageButton && { onClick: toggleLanguageMenu })}
              >
                Add Language
              </Button>
            </ListMenu>
          </>
        )}
      </Col>
    </Row>
  )
}

export default LanguageSelector
