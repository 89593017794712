import {
  Breadcrumbs,
  Button,
  Col,
  Container,
  Flexbox,
  Paper,
  Row,
  TextField,
  Typography,
} from '@icr-ui/core'
import { PlusIcon, SearchIcon } from '@icr-ui/icons'
import { SelectDataSkeleton } from 'components/skeletons'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppSelector } from 'store'
import { useGetComponentsQuery } from 'store/api'
import { IPage } from 'store/types'

const SelectComponent = () => {
  const { pageID } = useParams<{ pageID: string }>()
  const { currentComponentName } = useAppSelector(state => state.appState)
  const navigate = useNavigate()
  const { data } = useGetComponentsQuery(pageID as string)
  const [search, setSearch] = useState<string>('')
  const [filteredData, setFilteredData] = useState<IPage[]>([])

  useEffect(() => {
    if (data) {
      const filtered = data.filter(page => {
        return page.attributes.description.toLowerCase().includes(search.toLowerCase())
      })
      setFilteredData(filtered)
    }
  }, [search, data])

  return (
    <>
      <Container customStyles={{ paddingBottom: '0 !important' }}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Breadcrumbs
              items={[
                {
                  label: 'Home',
                  onClick: () => navigate(DefaultRoutes.APP),
                },
                {
                  label: 'Select Page',
                  onClick: () => navigate(DefaultRoutes.SELECT_PAGE),
                },
                {
                  label: 'Select Component',
                },
              ]}
            />
            <Button endAdornment={<PlusIcon />} disabled variant="text">
              Add New Component
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Paper
              fullWidth
              height="auto"
              noBg
              noRound
              noShadow
              noPadding
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Flexbox noPadding flexDirection="row" gap="1rem" alignItems="flex-start">
                <Typography variant="heading" size="h2">
                  {currentComponentName}
                </Typography>
              </Flexbox>
              <Flexbox width="30vw" noPadding>
                <TextField
                  id="search"
                  name="search"
                  placeholder="Suche"
                  value={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setSearch(e.target.value)
                  }}
                  customIcon={<SearchIcon />}
                />
              </Flexbox>
            </Paper>
          </Col>
        </Row>
      </Container>
      {!data ? (
        <SelectDataSkeleton />
      ) : (
        <Container customStyles={{ paddingTop: '0 !important' }}>
          <Row>
            {filteredData.map(component => (
              <Col key={component.id} xs={12} sm={6} md={6} lg={6}>
                <Paper
                  noPadding
                  fullWidth
                  fadeIn
                  withHover
                  flexDirection="row"
                  justifyContent="space-between"
                  onClick={() =>
                    navigate(
                      DefaultRoutes.SELECT_CONTENT.replace(':pageID', pageID as string)
                        .replace(':componentID', component.id)
                        .replace(':languageID', '1'),
                    )
                  }
                >
                  <Flexbox alignItems="flex-start">
                    <Typography variant="heading" size="h5">
                      {component.attributes.description}
                    </Typography>
                    <Typography variant="body" size="small">
                      Total Sections: 0
                    </Typography>
                  </Flexbox>
                  <Flexbox>
                    <Button variant="text">Edit</Button>
                  </Flexbox>
                </Paper>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  )
}

export default SelectComponent
