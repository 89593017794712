import * as yup from 'yup'

export const loginFormSchema = yup
  .object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  })
  .required()

export const forgotPasswordFormSchema = yup
  .object({
    username: yup.string().required('Benutzername wird benötigt'),
  })
  .required()

export const updateUserPasswordSchema = yup.object().shape({
  newPassword: yup.string().required('Neues Passwort erforderlich'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Die Passwörter müssen übereinstimmen')
    .required('Neues Passwort bestätigen ist erforderlich'),
})

export const userFormSchema = (t: (key: string, defaultValue: string) => string) =>
  yup.object({
    firstName: yup
      .string()
      .required(t('firstNameInput.validationMessage', 'Vorname ist erforderlich')),
    lastName: yup
      .string()
      .required(t('lastNameInput.validationMessage', 'Nachname ist erforderlich')),
    birthDate: yup
      .string()
      .required(t('birthDateInput.validationMessage', 'Geburtsdatum ist erforderlich')),
    mobileNumber: yup
      .string()
      .required(
        t('mobileNumberInput.validationMessage', 'Telefonnummer ist erforderlich'),
      ),
    email: yup
      .string()
      .email(t('emailInput.validationMessage.invalid', 'ungültiges Email-Format'))
      .required(t('emailInput.validationMessage.empty', 'E-Mail ist erforderlich')),
    socialSecurityNumber: yup
      .string()
      .required(
        t('socialSecurityNumberInput.validationMessage', 'AHV-Nummer ist erforderlich'),
      ),
    sex: yup
      .string()
      .required(t('sexInput.validationMessage', 'Geschlecht ist erforderlich')),
    sessionTimeout: yup
      .number()
      .typeError(
        t(
          'sessionTimeoutInput.numberValidationMessage',
          'Bitte geben Sie in dieses Feld nur Zahlen ein. Sonderzeichen, Buchstaben und Leerzeichen sind nicht erlaubt',
        ),
      )
      .required(
        t('sessionTimeoutInput.validationMessage', 'Session-Timeout ist erforderlich'),
      ),
    password: yup
      .string()
      .required(t('passwordInput.validationMessage', 'Passwort ist erforderlich')),
    companyId: yup.object().shape({
      value: yup
        .string()
        .required(t('companyInput.validationMessage', 'Firma ist erforderlich')),
    }),
    language: yup.object().shape({
      value: yup
        .string()
        .required(t('languageInput.validationMessage', 'Sprache ist erforderlich')),
    }),
    mfaType: yup.object().shape({
      value: yup
        .string()
        .required(
          t(
            'authTypeInput.validationMessage',
            'Authentifizierungsmethode ist erforderlich',
          ),
        ),
    }),
  })

export const userRolesFormSchema = yup
  .object({
    application: yup.object().shape({
      value: yup.number().required('Application is required'),
    }),
    role: yup.object().shape({
      value: yup.string().required('Role is required'),
    }),
  })
  .required()
