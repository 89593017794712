import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { resetAuthInfo, resetEnumerators, resetUserInfo } from 'store/slices'

const Logout = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(resetEnumerators())
    dispatch(resetAuthInfo())
    dispatch(resetUserInfo())
    localStorage.removeItem('persist:root')
    navigate('/login', { state })
  }, [])

  return null
}

export default Logout
