import { Button, Container, Layout, Loader, Row } from '@icr-ui/core'
import { ArrowLeftIcon } from '@icr-ui/icons'

import { FC } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppSelector } from 'store'
import { StateProps } from 'types'

interface ApplicationWrapperProps {
  isWrapperLoading: boolean
}

const ApplicationWrapper: FC<ApplicationWrapperProps> = ({ isWrapperLoading }) => {
  const {
    userState: user,
    appState: { currentTenant },
  } = useAppSelector(state => state)
  const navigate = useNavigate()
  const { state } = useLocation()
  const hasBackButton = state && (state as StateProps).hasBackButton

  return (
    <>
      {isWrapperLoading && <Loader />}
      <Layout
        headerProps={{
          title: `Translations Portal ${currentTenant ? '- ' + currentTenant : ''}`,
          userProfileProps: {
            accountProps: {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              logoutButtonAction: () => navigate(DefaultRoutes.LOGOUT),
              profileActions: [],
            },
          },
        }}
        contentStyles={{
          paddingInline: '40px',
          '@media screen and (max-width: 1025px)': { paddingInline: '32px' },
        }}
        setIsFilterOpen={() => void 0}
      >
        {hasBackButton ? (
          <Container
            fluid
            customStyles={{
              '@media screen and (max-width: 1024px)': { padding: '12px 0 32px 4px' },
            }}
          >
            <Row>
              <Button
                variant="text"
                startAdornment={<ArrowLeftIcon width={16} height={16} />}
                onClick={() => navigate(-1)}
              >
                Zurück
              </Button>
            </Row>
          </Container>
        ) : null}
        <Outlet />
      </Layout>
    </>
  )
}

export default ApplicationWrapper
