export enum DefaultRoutes {
  APP = '/',
  FORGOT_PASSWORD = '/forgot-password',
  UPDATE_USER_PASSWORD = '/update-user-password/:id',
  PROFILE = '/profile',
  LOGIN = '/login',
  LOGOUT = '/logout',
  RESET_PASSWORD = '/reset-password',
  SELECT_PAGE = '/select-page',
  SELECT_COMPONENT = '/select-component/:pageID',
  SELECT_CONTENT = '/select-content/:pageID/:componentID/:languageID',
}
