import {
  Button,
  Divider,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from '@icr-ui/core'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppDispatch, useAppSelector } from 'store'
import {
  setConfirmationModalOpen,
  setIsEditorDataChanged,
  setTriggerUpdate,
} from 'store/slices'

const ConfirmSaveModal = () => {
  const navigate = useNavigate()
  const { pageID, componentID } = useParams<{ pageID: string; componentID: string }>()
  const { confirmationModalOpen, currentLanguage, isSaving } = useAppSelector(
    state => state.contentState,
  )
  const dispatch = useAppDispatch()

  const toggleOpen = () => {
    dispatch(setConfirmationModalOpen(!confirmationModalOpen))
  }
  return (
    <Modal
      isOpen={confirmationModalOpen}
      setOpen={() => toggleOpen()}
      width="auto"
      hasCloseButton={false}
    >
      <ModalHeader>
        <Typography variant="heading" size="h1">
          Are you sure you want to change the language?
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Typography variant="body" size="normal">
          You have unsaved changes. Would you like to save them before changing language,
          or discard them?
        </Typography>
      </ModalContent>
      <Divider height="32px" noBorder />
      <ModalActions>
        <Button small onClick={() => toggleOpen()} variant="outlined">
          Cancel
        </Button>
        <Button
          small
          onClick={() => {
            dispatch(setTriggerUpdate(true))
          }}
          isLoading={isSaving}
        >
          Save & Switch Language
        </Button>
        <Button
          small
          onClick={() => {
            dispatch(setIsEditorDataChanged(false))
            toggleOpen()

            navigate(
              DefaultRoutes.SELECT_CONTENT.replace(':pageID', pageID as string)
                .replace(':componentID', componentID as string)
                .replace(':languageID', currentLanguage as string),
            )
          }}
          color="secondary"
        >
          Ignore & Switch Language
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ConfirmSaveModal
