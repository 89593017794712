import { Loader } from '@icr-ui/core'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from 'store'
import { useGetMeQuery } from 'store/api'

const ContinueWithToken = () => {
  const { username } = useAppSelector(state => state.userState)
  const { token } = useAppSelector(state => state.authState)

  const { isLoading, isFetching } = useGetMeQuery(
    { token },
    {
      skip: Boolean(username),
      refetchOnMountOrArgChange: true,
    },
  )

  if (isLoading || isFetching) {
    return <Loader />
  }
  if (username) {
    return <Outlet />
  }
  return null
}

const RequireUser = () => {
  const location = useLocation()
  const { token } = useAppSelector(state => state.authState)
  if (token.length) {
    // If a token exists then continue to app, if token is invalid will be caught by enpoint 401
    return <ContinueWithToken />
  }

  // If no token redirect to logout
  return <Navigate to="/logout" state={{ from: location }} replace />
}

export default RequireUser
