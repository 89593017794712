import { Button } from '@icr-ui/core'
import { createElement, Dispatch, ReactElement, SetStateAction } from 'react'
import escapeHtml from 'escape-html'
import { PlateEditor, serializeHtml, TText, Value } from '@udecode/plate'
import { TElement } from 'types/slate'
import { Text } from 'slate'
import { ArrowRightIcon } from '@icr-ui/icons'
import { palette } from '@icr-ui/theme'

export const printHtml = (
  editor: PlateEditor<Value>,
  value: (TElement | TText)[],
  setData: Dispatch<SetStateAction<{ rawData: string; htmlResult: string }>>,
) => {
  console.log('Serializing...')
  const html = serializeHtml(editor, {
    nodes: value,
    preserveClassNames: ['smart-'],
    stripWhitespace: true,
  })

  setData({
    rawData: JSON.stringify(value, null, 5),
    htmlResult: html,
  })
}

type copyDocumentToClipboardProps = {
  dataToCopy: string
  setShowSuccess: Dispatch<SetStateAction<boolean>>
}

export const copyDocumentToClipboard = async ({
  dataToCopy,
  setShowSuccess,
}: copyDocumentToClipboardProps) => {
  await navigator.clipboard.writeText(dataToCopy)
  setShowSuccess(true)
  setTimeout(() => {
    setShowSuccess(false)
  }, 3000)
}

enum HTMLAttrsToTags {
  bold = 'strong',
  italic = 'em',
  strikethrough = 'del',
  underline = 'u',
  code = 'code',
  superscript = 'sup',
  subscript = 'sub',
  color = 'span',
  highlight = 'span',
  backgroundColor = 'span',
  lic = 'span',
}

const htmlAttrs = Object.keys(HTMLAttrsToTags) as (keyof typeof HTMLAttrsToTags)[]
const htmlStyles = [htmlAttrs[7], htmlAttrs[8], htmlAttrs[9], htmlAttrs[10]]

export const serializeElements = (node: TElement) => {
  if (Text.isText(node)) {
    let string: any = escapeHtml(node.text)

    for (const attr of htmlAttrs) {
      if (node[attr]) {
        if (!htmlStyles.includes(node[attr])) {
          string = [createElement(HTMLAttrsToTags[attr], {}, string)]
        }

        if (node.color) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { color: node.color } },
              string,
            ),
          ]
        }

        if (node.highlight) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { backgroundColor: '#FEF3B7' } },
              string,
            ),
          ]
        }

        if (node.backgroundColor) {
          string = [
            createElement(
              HTMLAttrsToTags[attr],
              { style: { backgroundColor: node.backgroundColor } },
              string,
            ),
          ]
        }
      }
    }
    return string
  } else if (node.children) {
    return serializeChildren(node)
  }
}

export const serializeChildren = (node: TElement) => {
  const children = node.children.map(n => serializeElements(n as TElement))
  let html: ReactElement[] = []
  switch (node.type) {
    case 'h1':
      html = [createElement('h1', { className: 'mb-16', key: node.id }, children)]
      break
    case 'h2':
      html = [createElement('h2', { className: 'mb-16', key: node.id }, children)]
      break
    case 'h3':
      html = [createElement('h3', { className: 'mb-16', key: node.id }, children)]
      break
    case 'h4':
      html = [createElement('h4', { className: 'mb-16', key: node.id }, children)]
      break
    case 'h5':
      html = [createElement('h5', { className: 'mb-16', key: node.id }, children)]
      break
    case 'h6':
      html = [createElement('h6', { className: 'mb-16', key: node.id }, children)]
      break
    case 'p':
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [createElement('p', { className: 'mb-24', key: node.id }, children)]
      } else {
        node.children.map(n => serializeElements(n as TElement))
      }
      html = [createElement('p', { className: 'mb-24', key: node.id }, children)]
      break
    case 'ul':
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [createElement('ul', { className: 'mb-16', key: node.id }, children)]
      } else {
        node.children.map(n => serializeElements(n as TElement))
      }
      html = [createElement('ul', { className: 'mb-48', key: node.id }, children)]
      break
    case 'li':
      if (node.children.length === 1 && Text.isText(node.children[0])) {
        html = [createElement('li', { className: 'mb-16', key: node.id }, children)]
      } else {
        node.children.map(n => serializeElements(n as TElement))
      }
      html = [createElement('li', { className: 'mb-16', key: node.id }, children)]
      break
    case 'lic':
      html = [createElement('span', { key: node.id }, children)]
      break
    case 'a':
      html = [
        <Button
          key={1}
          type="button"
          onClick={() => (window.location.href = node.url as string)}
          className="m-0 p-0 inline-block"
        >
          {children}
          <ArrowRightIcon className="ml-16" color={palette.primary.main} />
        </Button>,
      ]
      break
    default:
      html = [createElement(node.type, { id: node.id }, children)]
  }

  return html
}
