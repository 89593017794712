import styled, { css } from 'styled-components'
import { defaultTheme as theme, primary, grayScale } from '@icr-ui/theme'
import { pxToRem } from '@icr-ui/utils'

export const StyledListItem = styled.li`
  list-style: none;
  font-weight: 600;
  width: 100%;
  padding: ${pxToRem(25)} 0;
  background-color: white;
  transition: all 0.3s ease-out;
  cursor: pointer;
  color: ${primary.main};

  &:hover {
    color: ${primary.dark};
  }

  & + & {
    border-top: 1px solid ${grayScale[200]};
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`

export const StyledList = styled.ul`
  position: absolute;
  top: 2.5rem;
  right: 0;
  max-height: 0;
  min-width: ${pxToRem(335)};
  background-color: white;
  z-index: 999;
  overflow: hidden;
  border-radius: ${pxToRem(8)};
  padding: 0;
  box-shadow: ${theme.shadows.sticky};
  transition: all 0.2s linear;

  * {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
`

export const StyledWrapper = styled.div<{
  isOpen?: boolean
}>`
  position: relative;
  transition: all 0.2s ease-out;

  ${StyledList} {
    ${props =>
      props.isOpen &&
      css`
        padding: ${pxToRem(32)} ${pxToRem(24)};
        max-height: 600px;

        * {
          opacity: 1;
        }
      `}
  }
`
