import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppState } from 'store/types'

const initialState: IAppState = {
  isWrapperLoading: false,
}

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isWrapperLoading = action.payload
    },
    setCurrentTenant: (state, action: PayloadAction<string>) => {
      state.currentTenant = action.payload
    },

    setCurrentComponentName: (state, action: PayloadAction<string>) => {
      state.currentComponentName = action.payload
    },
  },
})

export default appSlice.reducer

export const {
  setAppLoading,
  setCurrentTenant,

  setCurrentComponentName,
} = appSlice.actions
