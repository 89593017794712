import { IPageResponse } from 'store/types'
import { protectedApi } from '../api'

export const pageApi = protectedApi.injectEndpoints({
  endpoints: builder => ({
    getPages: builder.query<IPageResponse['data'], string>({
      query(tenantCode) {
        return {
          url: `Object/ObjectCustomizationSelect/${tenantCode}`,
          method: 'GET',
        }
      },
      transformResponse: (response: IPageResponse) => {
        const { data } = response
        return data
      },
    }),
  }),
})

export const { useGetPagesQuery } = pageApi
