import { styled } from '@icr-ui/core'

interface Props {
  tooltipOpen: boolean
}

//ToDo: refactor this into it's own component
export const CopyDocumentContainer = styled('a')<Props>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  color: #444;
  padding-block: 15px;
  border-bottom: 1px solid #444;
  border-top: 1px solid #444;
  width: 100%;
`
