import { Button, Flexbox, Typography } from '@icr-ui/core'
import { useNavigate } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppDispatch } from 'store'
import { setCurrentTenant } from 'store/slices'

const Home = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleButtonClick = (tenant: string) => {
    dispatch(setCurrentTenant(tenant))
    navigate(DefaultRoutes.SELECT_PAGE)
  }
  return (
    <Flexbox fullHeight fullWidth alignItems="center" justifyContent="center">
      <Typography variant="heading" size="h2" gutterBottom="5rem">
        Dashboard Comming Soon...
      </Typography>
      <Flexbox flexDirection="row" gap="1rem">
        <Button onClick={() => handleButtonClick('PKSO')}>
          Manage Translations for PKSO
        </Button>
        <Button onClick={() => handleButtonClick('VISANA')}>
          Manage Translations for VISANA
        </Button>
      </Flexbox>
    </Flexbox>
  )
}

export default Home
