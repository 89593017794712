import { useState } from 'react'
import { copyDocumentToClipboard } from 'utils'
import CodeMirror from '@uiw/react-codemirror'
import { dracula } from '@uiw/codemirror-theme-dracula'
import { Col, Paper, Typography } from '@icr-ui/core'
import { palette } from '@icr-ui/theme'
import { DocumentMutationIcon } from '@icr-ui/icons'
import { CopyDocumentContainer } from './styles'

type RawDataProps = {
  rawData: string
}

const RawData = ({ rawData }: RawDataProps) => {
  const [showSuccess, setShowSuccess] = useState(false)
  return (
    <Col
      as={Paper}
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      customStyles={{ width: '100%' }}
    >
      <Typography
        variant="heading"
        size="h2"
        customStyles={{
          borderBottom: `1px solid ${palette.grayScale[500]}`,
          width: '100%',
        }}
      >
        Raw Data (Json)
        <Typography variant="body" size="tiny" gutterLeft="8px">
          - the data you gonna insert into database
        </Typography>
      </Typography>

      <CodeMirror
        value={rawData}
        height="26.3vh"
        theme={dracula}
        style={{ width: '100%' }}
      />
      <CopyDocumentContainer
        onClick={() => copyDocumentToClipboard({ dataToCopy: rawData, setShowSuccess })}
        tooltipOpen={showSuccess}
      >
        <DocumentMutationIcon />
        <Typography variant="body" size="small">
          {showSuccess ? (
            <>
              <strong>Copied!</strong>
            </>
          ) : (
            <>
              Copy the <strong>raw data</strong> schema to the clipboard
            </>
          )}
        </Typography>
      </CopyDocumentContainer>
    </Col>
  )
}

export default RawData
