import {
  IContentResponse,
  IContentRequestParams,
  IApiResponse,
  IUpdateContentRequestParams,
} from 'store/types'
import { protectedApi } from '../api'

export const contentApi = protectedApi.injectEndpoints({
  endpoints: builder => ({
    getContents: builder.query<IContentResponse['data'], IContentRequestParams>({
      query({ pageId, componentId }) {
        return {
          url: `Object/${pageId}/Component/${componentId}`,
          method: 'GET',
        }
      },
      transformResponse: (response: IContentResponse) => {
        const { data } = response
        return data
      },
      providesTags: ['GetContents'],
    }),
    updateContent: builder.mutation<IApiResponse, IUpdateContentRequestParams>({
      query({ pageId, componentId, data }) {
        return {
          url: `Object/${pageId}/Component/${componentId}`,
          method: 'PUT',
          body: {
            data,
          },
        }
      },
      invalidatesTags: ['GetContents'],
    }),
  }),
})

export const { useGetContentsQuery, useUpdateContentMutation } = contentApi
