import { DropdownItemProps } from '@icr-ui/core'
import { setLanguagesInfo } from 'store/slices'
import { IGetLanguagesResponse, ILanguages } from 'store/types'
import { protectedApi } from '../api'

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT

export const enumeratorApi = protectedApi.injectEndpoints({
  endpoints: builder => ({
    getLanguages: builder.query<IGetLanguagesResponse, void>({
      query: () => `${baseUrl}Enumerator/Language`,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const response = (await queryFulfilled).data.data
        const transformedResults: DropdownItemProps[] = response.reduce(
          (acc: DropdownItemProps[], curr: ILanguages) => {
            const { id, attributes } = curr
            const storeApps: DropdownItemProps = {
              value: id as string,
              label: attributes.name,
            }
            acc.push(storeApps)
            return acc
          },
          [],
        )
        dispatch(setLanguagesInfo(transformedResults))
      },
    }),
  }),
})
