import { setAuthInfo } from 'store/slices/authSlice'
import {
  GenericResponse,
  IAuthRequest,
  IAuthResponse,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  IResetPasswordRequest,
  IVerifyEmailRequest,
} from 'store/types'
import { publicAPI } from './api'
import { defaultBody } from './constants'
import { enumeratorApi } from './administration/enumeratorApi'

export const authApi = publicAPI.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation<IAuthResponse, IAuthRequest>({
      query(data) {
        return {
          url: 'Authenticate/Login',
          method: 'POST',
          body: defaultBody(data),
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: {
              token,
              data: {
                logonLogEntry: { userId },
              },
            },
          } = await queryFulfilled

          dispatch(setAuthInfo({ token, userID: userId }))
          dispatch(enumeratorApi.endpoints.getLanguages.initiate())
        } catch (error) {
          console.error(error)
        }
      },
    }),
    forgotPassword: builder.mutation<IForgotPasswordResponse, IForgotPasswordRequest>({
      query(data) {
        return {
          url: 'Authenticate/PasswordForgot',
          method: 'POST',
          body: defaultBody(data),
        }
      },
    }),
    verifyEmail: builder.mutation<GenericResponse, IVerifyEmailRequest>({
      query({ token, emailCode }) {
        return {
          url: `Authenticate/ValidateEmailToken/${token}`,
          method: 'POST',
          body: { emailCode },
        }
      },
    }),
    resetPassword: builder.mutation<GenericResponse, IResetPasswordRequest>({
      query(data) {
        return {
          url: 'Authenticate/PasswordReset',
          method: 'POST',
          body: defaultBody(data),
        }
      },
    }),
  }),
})

export const {
  useLoginUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi
