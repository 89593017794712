import {
  Breadcrumbs,
  Col,
  Container,
  Flexbox,
  Paper,
  Row,
  Typography,
} from '@icr-ui/core'
import { ConfirmSaveModal } from 'components'
import { Editor, HTMLResults, RawData } from 'components/boxes'
import { EditorSkeleton } from 'components/skeletons'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppDispatch, useAppSelector } from 'store'
import { useGetContentsQuery } from 'store/api/translation/contentApi'
import { setAvailableContent, setEditorValue } from 'store/slices'
import { IContents } from 'store/types'
import LanguageSelector from './language-selector'

const SelectContent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pageID, componentID, languageID } = useParams<{
    pageID: string
    componentID: string
    languageID: string
  }>()
  const {
    contentState: { editorValue, availableContent, isSaving },
  } = useAppSelector(state => state)
  const [data, setData] = useState({
    rawData: '',
    htmlResult: '',
  })

  const { data: contentData, isFetching } = useGetContentsQuery({
    pageId: pageID as string,
    componentId: componentID as string,
  })

  const contentsData = contentData?.attributes.componentAttributes.contents
  const containerProps = { paddingBottom: '0 !important' }

  const componentName = useMemo(() => {
    if (contentData?.attributes.name) {
      return `(${contentData.attributes.name})`
    }
    return ''
  }, [contentData])

  const handleChangeContentData = () => {
    if (contentsData === undefined) return
    if (languageID === undefined) return
    try {
      const currentEditorContent = contentsData.find(
        content => parseInt(content.language) === parseInt(languageID as string),
      ) as IContents
      const replaced = currentEditorContent.value.replace(/'/g, "'")
      const parsed = JSON.parse(replaced)
      dispatch(setEditorValue(parsed))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (contentsData && !!contentsData.length && !editorValue) {
      const parsed = JSON.parse(contentsData[0].value)
      dispatch(setEditorValue(parsed))
    }
  }, [contentsData, editorValue])

  useEffect(() => {
    if (contentData) {
      dispatch(setAvailableContent(contentData))
    }
  }, [contentData, availableContent])

  useEffect(() => {
    handleChangeContentData()
  }, [languageID, contentsData])

  const [isEditorLoading, setIsEditorLoading] = useState(false)
  useEffect(() => {
    setIsEditorLoading(true)
    setTimeout(() => {
      setIsEditorLoading(false)
    }, 1000)
  }, [editorValue])

  return (
    <>
      <Container customStyles={containerProps}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Breadcrumbs
              items={[
                {
                  label: 'Home',
                  onClick: () => navigate(DefaultRoutes.APP),
                },
                {
                  label: 'Select Page',
                  onClick: () => navigate(DefaultRoutes.SELECT_PAGE),
                },
                {
                  label: 'Select Component',
                  onClick: () =>
                    navigate(
                      DefaultRoutes.SELECT_COMPONENT.replace(
                        ':pageID',
                        pageID as string,
                      ).replace(':componentID', componentID as string),
                    ),
                },
                {
                  label: `Manage Content ${componentName}`,
                },
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Paper
              fullWidth
              height="auto"
              noBg
              noRound
              noShadow
              noPadding
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Flexbox noPadding flexDirection="row" gap="1rem" alignItems="flex-start">
                <Typography variant="heading" size="h2" gutterTop="1rem">
                  Edit Insurant Portal Content {componentName}
                </Typography>
              </Flexbox>
            </Paper>
          </Col>
        </Row>
      </Container>
      {!contentData || isSaving || isFetching ? (
        <Container fluid customStyles={containerProps}>
          <EditorSkeleton />
        </Container>
      ) : (
        <Container customStyles={{ paddingTop: '0 !important' }}>
          <LanguageSelector {...{ contentsData }} />
          {contentsData && (
            <Row alignItems="flex-start">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Editor {...{ setData, editorValue }} />
              </Col>
              {!isEditorLoading && (
                <>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <HTMLResults htmlResult={data.htmlResult} />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <RawData rawData={data.rawData} />
                  </Col>
                </>
              )}
            </Row>
          )}
        </Container>
      )}
      <ConfirmSaveModal />
    </>
  )
}

export default SelectContent
