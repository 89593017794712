import { setUser } from 'store/slices'
import { IGetMeRequest, IGetUserResponse } from 'store/types'
import { protectedApi } from '../api'

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT

export const userApi = protectedApi.injectEndpoints({
  endpoints: builder => ({
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: `${baseUrl}Authenticate/Logout`,
          credentials: 'include',
        }
      },
    }),
    getMe: builder.query<IGetUserResponse, IGetMeRequest>({
      query(data) {
        return {
          url: `${baseUrl}User/MyInformation`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: {
              data: { id, attributes },
            },
          } = await queryFulfilled
          const { email, firstName, lastName, username } = attributes
          dispatch(
            setUser({
              email,
              firstName,
              lastName,
              userId: id as string,
              username,
            }),
          )
        } catch (error) {
          console.error(error)
        }
      },
    }),
  }),
})

export const { useGetMeQuery, useLogoutUserMutation } = userApi
