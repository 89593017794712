import { styled } from '@icr-ui/core'
import { grayScale } from '@icr-ui/theme'

export type SkeletonRowItemProps = {
  height: string
}

const skeleton = styled.div`
  background: linear-gradient(
    90deg,
    ${grayScale[300]} 0%,
    ${grayScale[100]} 36.66%,
    ${grayScale[300]} 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonRowItem = styled(skeleton)<SkeletonRowItemProps>`
  width: calc(100% - 40px);
  height: ${({ height }) => height};
  border-radius: 8px;
`
