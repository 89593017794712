import { Flexbox, Typography } from '@icr-ui/core'

import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from 'store'

// Role types match those found in the IUser interface
type Role =
  | 'System Administrator'
  | 'Application Administrator'
  | 'Company Administrator'
  | 'No Default Role'
  | 'User'

type Props = {
  restrictTo?: Role[]
  ignoreUserData?: boolean
}

export default function RequireAuth({ restrictTo, ignoreUserData }: Props) {
  const auth = useAppSelector(state => state.authState)
  const user = useAppSelector(state => state.userState)
  const token = auth.token
  const hasToken = token.length

  const location = useLocation()

  // If there is no restrictTo, has token and does not need the user details then permit access (update-password)
  if (ignoreUserData && hasToken && !restrictTo) {
    return <Outlet />
  }

  // If there is no user or token present at all then user needs to login so redirect
  if (!user || !hasToken) {
    return <Navigate to="/logout" state={{ from: location }} replace />
  }

  // If there is no restrictTo then permit access
  if (!restrictTo) {
    return <Outlet />
  }

  // If restrictTo is present then verify that the user has the relevant permissions

  // if (restrictTo) {
  //   let permitAccess = false
  //   restrictTo.forEach(restriction => {
  //     if (user.roles.find(role => role.roleName === restriction)) permitAccess = true
  //   })

  //   if (permitAccess) {
  //     return <Outlet />
  //   }
  // }

  // If none of the above the no permissions
  return (
    <Flexbox fullWidth fullHeight>
      <Typography variant="heading" size="h4" gutterBottom="16px">
        401 - Page Not Found
      </Typography>
      <Typography variant="body" size="normal">
        Sorry, the page you are looking for could not be found
      </Typography>
    </Flexbox>
  )
}
