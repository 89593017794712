import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from 'store/types'

const initialState: IUser = {
  firstName: '',
  lastName: '',
  email: '',
  userId: '',
  username: '',
}

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    resetUserInfo: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.userId = action.payload.userId
      state.username = action.payload.username
    },
  },
})

export default userSlice.reducer

export const { resetUserInfo, setUser } = userSlice.actions
