import { ApplicationWrapper, RequireAuth, RequireUser } from 'components'
import {
  ForgotPassword,
  Home,
  Login,
  Logout,
  ResetPassword,
  SelectComponent,
  SelectContent,
  SelectPage,
} from 'pages'
import { Route, Routes } from 'react-router-dom'
import { useAppSelector } from 'store'
import { DefaultRoutes } from './routes'

const Navigation = () => {
  const { isWrapperLoading } = useAppSelector(state => state.appState)

  return (
    <Routes>
      <Route
        path={DefaultRoutes.APP}
        element={<ApplicationWrapper {...{ isWrapperLoading }} />}
      >
        <Route element={<RequireUser />}>
          <Route element={<RequireAuth />}>
            <Route index element={<Home />} />
            <Route path={DefaultRoutes.SELECT_PAGE} element={<SelectPage />} />
            <Route path={DefaultRoutes.SELECT_COMPONENT} element={<SelectComponent />} />
            <Route path={DefaultRoutes.SELECT_CONTENT} element={<SelectContent />} />
          </Route>
        </Route>
        <Route path={DefaultRoutes.LOGOUT} element={<Logout />} />
      </Route>
      <Route path={DefaultRoutes.LOGIN} element={<Login {...{ isWrapperLoading }} />} />
      <Route path={DefaultRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={DefaultRoutes.RESET_PASSWORD} element={<ResetPassword />} />
    </Routes>
  )
}

export default Navigation
