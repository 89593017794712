import { DropdownItemProps } from '@icr-ui/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEnumeratorsState } from 'store/types'

const initialState: IEnumeratorsState = {
  languages: [],
}

export const enumeratorSlice = createSlice({
  initialState,
  name: 'enumeratorSlice',
  reducers: {
    resetEnumerators: () => initialState,

    setLanguagesInfo: (
      state: IEnumeratorsState,
      action: PayloadAction<DropdownItemProps[]>,
    ) => {
      state.languages = action.payload
    },
  },
})

export default enumeratorSlice.reducer

export const { resetEnumerators, setLanguagesInfo } = enumeratorSlice.actions
