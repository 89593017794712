import { useState } from 'react'

import { copyDocumentToClipboard } from 'utils'
import { Col, Flexbox, Paper, Typography } from '@icr-ui/core'
import { palette } from '@icr-ui/theme'
import { DocumentMutationIcon } from '@icr-ui/icons'
import { CopyDocumentContainer } from './styles'

type HTMLResultsProps = {
  htmlResult: string
}

const HTMLResults = ({ htmlResult }: HTMLResultsProps) => {
  const [showSuccess, setShowSuccess] = useState(false)
  return (
    <Col as={Paper} width="100%" flexDirection="column" alignItems="flex-start">
      <Typography
        variant="heading"
        size="h2"
        customStyles={{
          borderBottom: `1px solid ${palette.grayScale[500]}`,
          width: '100%',
        }}
      >
        HTML Results
        <Typography variant="body" size="tiny" gutterLeft="8px">
          - transpiled data that will be rendered in the app
        </Typography>
      </Typography>

      <Flexbox
        noWrap
        fullWidth
        alignItems="flex-start"
        customStyles={{
          gap: 20,
          marginBlock: 0,
          height: '25vh',
          overflowY: 'auto',
          backgroundColor: palette.grayScale[300],
        }}
        dangerouslySetInnerHTML={{ __html: htmlResult }}
      />
      <CopyDocumentContainer
        onClick={() =>
          copyDocumentToClipboard({ dataToCopy: htmlResult, setShowSuccess })
        }
        tooltipOpen={showSuccess}
      >
        <DocumentMutationIcon />
        <Typography variant="body" size="small">
          {showSuccess ? (
            <>
              <strong>Copied!</strong>
            </>
          ) : (
            <>
              Copy the <strong>converted HTML</strong> to the clipboard
            </>
          )}
        </Typography>
      </CopyDocumentContainer>
    </Col>
  )
}

export default HTMLResults
