import { yupResolver } from '@hookform/resolvers/yup'
import {
  Background,
  Button,
  Checkbox,
  Flexbox,
  Loader,
  Paper,
  TextField,
  Typography,
  useToast,
} from '@icr-ui/core'
import { useMediaQuery } from '@icr-ui/hooks'
import { ICRLogoIcon } from '@icr-ui/icons'
import { loginFormSchema } from 'constant'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useLoginUserMutation } from 'store/api'
import { IAuthRequest } from 'store/types'

interface LoginProps {
  isWrapperLoading: boolean
}

const Login = ({ isWrapperLoading }: LoginProps) => {
  const shouldRememberMe = JSON.parse(localStorage.getItem('rememberMe') || '{}')
  const [rememberMe, setRememberMe] = useState(shouldRememberMe.state)

  const isSmallScreen = useMediaQuery('(max-width: 883px)')
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IAuthRequest>({
    mode: 'onBlur',
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      username: shouldRememberMe.username || '',
      password: '',
    },
  })

  const [loginUser, { isLoading, isError, error, isSuccess }] = useLoginUserMutation()

  const { message } = useToast()

  useEffect(() => {
    if (isSuccess) {
      navigate(DefaultRoutes.APP)
    }
    if (isError && error) {
      if (Array.isArray((error as any).data.errors)) {
        message.error((error as any).data.errors[0].detail)
      } else {
        message.error((error as any).data.detail)
      }
    }
  }, [isLoading])

  const onSubmit = (data: IAuthRequest) => {
    loginUser(data)
  }

  const [username, password] = watch(['username', 'password'])
  const handleRememberMe = (rememberMe: boolean) => {
    localStorage.setItem(
      'rememberMe',
      JSON.stringify({
        state: rememberMe,
        username: rememberMe ? username : '',
      }),
    )
    setRememberMe(rememberMe)
  }

  return (
    <Flexbox padding="sm" fullScreen justifyContent="center">
      <Background />
      {isWrapperLoading && <Loader />}
      <Paper
        alignItems="center"
        justifyContent="space-between"
        width={isSmallScreen ? '88vw' : 400}
        padding={isSmallScreen ? 'sm' : 'md'}
        flexDirection="row"
        gap={isSmallScreen ? '32px' : '48px'}
        customStyles={{ zIndex: 1 }}
      >
        <Flexbox
          fullWidth
          noPadding
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          customStyles={{
            marginTop: isSmallScreen ? 0 : 16,
          }}
        >
          <ICRLogoIcon width={80} height={28.3} />
        </Flexbox>
        <Flexbox
          as="form"
          fullWidth
          noPadding
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="heading" size="h1" gutterBottom="32px">
            Benutzerverwaltung
          </Typography>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                id="username"
                {...field}
                type="text"
                label="Nutzername"
                placeholder="icr-adm"
                isValid={isTouched && !error}
                messageType={error ? 'error' : undefined}
                helperText={errors.username?.message}
                disabled={isLoading}
                isRequired
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                id="password"
                {...field}
                type="password"
                showIconPassword
                label="Passwort"
                placeholder="*****"
                isValid={isTouched && !error}
                messageType={error ? 'error' : undefined}
                helperText={errors.password?.message}
                disabled={isLoading}
                isRequired
              />
            )}
          />
          <Flexbox
            fullWidth
            justifyContent="space-between"
            flexDirection="row"
            noPadding
            customStyles={{
              margin: isSmallScreen ? '0 0 32px' : '16px 0 48px',
            }}
          >
            <Checkbox
              label="Login merken"
              isSelected={rememberMe}
              setIsSelected={(isSelected: boolean) => {
                handleRememberMe(isSelected)
              }}
              disabled={!username || isLoading}
            />
            {!isSmallScreen && (
              <Button
                onClick={() => navigate(DefaultRoutes.FORGOT_PASSWORD)}
                variant="text"
                type="button"
              >
                Passwort vergessen
              </Button>
            )}
          </Flexbox>
          {isSmallScreen && (
            <Button
              onClick={() => navigate(DefaultRoutes.FORGOT_PASSWORD)}
              variant="text"
              type="button"
              fullWidth
            >
              Passwort vergessen
            </Button>
          )}
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            type="submit"
            disabled={!username || !password}
            isLoading={isLoading}
            customStyles={{
              alignSelf: 'flex-end',
              margin: isSmallScreen ? '24px 0' : '0px',
            }}
          >
            anmelden
          </Button>
        </Flexbox>
      </Paper>
    </Flexbox>
  )
}

export default Login
