import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { protectedApi, publicAPI } from './api'
import {
  userReducer,
  authReducer,
  enumeratorReducer,
  appReducer,
  contentReducer,
} from './slices'

const rootReducer = combineReducers({
  [protectedApi.reducerPath]: protectedApi.reducer,
  [publicAPI.reducerPath]: publicAPI.reducer,
  userState: userReducer,
  authState: authReducer,
  enumeratorState: enumeratorReducer,
  appState: appReducer,
  contentState: contentReducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['appState', 'userState', 'authState', 'enumeratorState'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([publicAPI.middleware, protectedApi.middleware]),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
