import { Button, Divider, Flexbox, Paper, Typography } from '@icr-ui/core'
import { palette } from '@icr-ui/theme'
import {
  HeadingToolbar,
  Plate,
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodePlugin,
  createDeserializeCsvPlugin,
  createDeserializeDocxPlugin,
  createDeserializeMdPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createFontSizePlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHorizontalRulePlugin,
  createIndentPlugin,
  createItalicPlugin,
  createJuicePlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createNodeIdPlugin,
  createNormalizeTypesPlugin,
  createParagraphPlugin,
  createPlateEditor,
  createPlateUI,
  createPlugins,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
} from '@udecode/plate'
import { EditorSkeleton } from 'components/skeletons'
import { MarkBallonToolbar, ToolbarButtons } from 'config/components/Toolbars'
import { withStyledPlaceHolders } from 'config/components/withStyledPlaceHolders'
import { CONFIG } from 'config/config'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useAppDispatch, useAppSelector } from 'store'
import { useUpdateContentMutation } from 'store/api/translation/contentApi'
import {
  setChangedEditorValue,
  setConfirmationModalOpen,
  setIsEditorDataChanged,
  setIsSaving,
  setTriggerUpdate,
} from 'store/slices'
import { IContent, IContents } from 'store/types'
import { TElement } from 'types'
import { printHtml } from 'utils'

const id = 'Components/Editor'

let components = createPlateUI()
components = withStyledPlaceHolders(components)

type EditorProps = {
  setData: Dispatch<SetStateAction<{ rawData: string; htmlResult: string }>>
}

const Editor = ({ setData }: EditorProps) => {
  const { pageID, componentID, languageID } = useParams<{
    pageID: string
    componentID: string
    languageID: string
  }>()
  const dispatch = useAppDispatch()

  const plugins = createPlugins(
    [
      createParagraphPlugin(),
      createBlockquotePlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createHorizontalRulePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createAlignPlugin(CONFIG.align),
      createBoldPlugin(),
      createCodePlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createFontSizePlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createIndentPlugin(CONFIG.indent),
      createAutoformatPlugin(CONFIG.autoformat),
      createResetNodePlugin(CONFIG.resetBlockType),
      createSoftBreakPlugin(CONFIG.softBreak),
      createExitBreakPlugin(CONFIG.exitBreak),
      createNormalizeTypesPlugin(),
      createTrailingBlockPlugin(CONFIG.trailingBlock),
      createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
      createDeserializeMdPlugin(),
      createDeserializeCsvPlugin(),
      createDeserializeDocxPlugin(),
      createJuicePlugin(),
    ],
    {
      components,
    },
  )

  const [isEditorLoading, setIsEditorLoading] = useState(false)
  const editor = createPlateEditor({
    plugins: plugins,
  })
  const navigate = useNavigate()
  const {
    editorValue,
    availableContent,
    isEditorDataChanged,
    triggerUpdate,
    confirmationModalOpen,
    currentLanguage,
    isSaving,
    changedEditorValue: slateValue,
  } = useAppSelector(state => state.contentState)

  useEffect(() => {
    dispatch(setChangedEditorValue(editorValue as TElement[]))
  }, [editorValue])

  useEffect(() => {
    if (slateValue !== editorValue && !isEditorDataChanged) {
      dispatch(setIsEditorDataChanged(true))
    } else if (slateValue === editorValue && isEditorDataChanged) {
      dispatch(setIsEditorDataChanged(false))
    }
  }, [slateValue, editorValue, isEditorDataChanged])

  useEffect(() => {
    setIsEditorLoading(true)
    setTimeout(() => {
      setIsEditorLoading(false)
    }, 1000)
  }, [editorValue])

  const [updateContent, { isLoading: isContentLoading }] = useUpdateContentMutation()

  const handleUpdateContent = () => {
    if (availableContent) {
      const newValue = JSON.stringify(slateValue)
      const contents = [
        ...availableContent.attributes.componentAttributes.contents,
      ] as IContents[]

      const reducedContents = contents.reduce((acc: IContents[], curr: IContents) => {
        if (parseInt(curr.language) === parseInt(languageID as string)) {
          return [...acc, { ...curr, value: newValue }]
        } else {
          return [...acc, curr]
        }
      }, [])

      const newContent: IContent = {
        ...availableContent,
        attributes: {
          ...availableContent.attributes,
          componentAttributes: {
            ...availableContent.attributes.componentAttributes,
            contents: reducedContents,
          },
        },
      }

      updateContent({
        data: newContent,
        pageId: pageID as string,
        componentId: componentID as string,
      })
      dispatch(setIsEditorDataChanged(false))
      dispatch(setTriggerUpdate(false))
      dispatch(setIsSaving(false))

      if (confirmationModalOpen && languageID !== currentLanguage) {
        dispatch(setConfirmationModalOpen(false))
        navigate(
          DefaultRoutes.SELECT_CONTENT.replace(':pageID', pageID as string)
            .replace(':componentID', componentID as string)
            .replace(':languageID', currentLanguage as string),
        )
      }
    }
  }

  useEffect(() => {
    if (triggerUpdate) {
      dispatch(setIsSaving(true))
      handleUpdateContent()
    }
  }, [triggerUpdate])

  return isEditorLoading || isSaving ? (
    <EditorSkeleton />
  ) : (
    <Paper width="100%" flexDirection="column" alignItems="flex-start">
      <Flexbox fullWidth noPadding flexDirection="row" justifyContent="space-between">
        <Typography variant="heading" size="h2">
          Editor
          <Typography variant="body" size="tiny" gutterLeft="8px">
            - this is where you do your magic ✨
          </Typography>
        </Typography>
        <Button
          small
          onClick={() => handleUpdateContent()}
          isLoading={isContentLoading}
          disabled={!isEditorDataChanged}
        >
          Save Changes
        </Button>
      </Flexbox>
      <Divider height="32px" noBorder />
      <Flexbox
        noPadding
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="initial"
        height="61.2vh"
        customStyles={{
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: palette.grayScale[300],
          position: 'relative',
          flexWrap: 'wrap',
        }}
      >
        <Plate
          id={id}
          editableProps={CONFIG.editableProps}
          plugins={plugins}
          value={slateValue}
          editor={editor}
          onChange={(value: any[]) => {
            printHtml(editor, value, setData)
            dispatch(setChangedEditorValue(value))
          }}
        >
          <HeadingToolbar
            style={{
              position: 'sticky',
              top: 0,
              padding: 8,
              margin: 0,
              backgroundColor: palette.primary.lightest,
              width: '100%',
              zIndex: 10,
              alignItems: 'center',
              justifyContent: 'stretch',
            }}
          >
            <ToolbarButtons />
          </HeadingToolbar>

          <MarkBallonToolbar />
        </Plate>
      </Flexbox>
    </Paper>
  )
}

export default Editor
