import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAuthState } from 'store/types'

const initialState: IAuthState = {
  token: '',
  userID: '',
}

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    resetAuthInfo: () => initialState,
    setAuthInfo: (state, action: PayloadAction<IAuthState>) => {
      state.token = action.payload.token
      state.userID = action.payload.userID
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})

export default authSlice.reducer

export const { resetAuthInfo, setAuthInfo, updateToken } = authSlice.actions
