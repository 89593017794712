import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IContent, IContents, IContentState } from 'store/types'
import { TElement } from 'types'

const initialState: IContentState = {
  editorValue: undefined,
  currentContent: undefined,
  changedEditorValue: undefined,
  currentLanguage: '1',
  availableContent: undefined,
  isEditorDataChanged: false,
  triggerUpdate: false,
  confirmationModalOpen: false,
  isSaving: false,
}

export const contentSlice = createSlice({
  initialState,
  name: 'contentSlice',
  reducers: {
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload
    },
    setEditorValue: (state, action: PayloadAction<TElement[]>) => {
      state.editorValue = action.payload
    },
    setCurrentContent: (state, action: PayloadAction<IContents>) => {
      state.currentContent = action.payload
    },
    setCurrentLanguage: (state, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload
    },
    setAvailableContent: (state, action: PayloadAction<IContent>) => {
      state.availableContent = action.payload
    },
    setIsEditorDataChanged: (state, action: PayloadAction<boolean>) => {
      state.isEditorDataChanged = action.payload
    },
    setTriggerUpdate: (state, action: PayloadAction<boolean>) => {
      state.triggerUpdate = action.payload
    },
    setConfirmationModalOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmationModalOpen = action.payload
    },
    setChangedEditorValue: (state, action: PayloadAction<TElement[]>) => {
      state.changedEditorValue = action.payload
    },
  },
})

export default contentSlice.reducer

export const {
  setEditorValue,
  setCurrentContent,
  setCurrentLanguage,
  setAvailableContent,
  setIsEditorDataChanged,
  setTriggerUpdate,
  setConfirmationModalOpen,
  setChangedEditorValue,
  setIsSaving,
} = contentSlice.actions
