import { yupResolver } from '@hookform/resolvers/yup'
import { Background, Button, Flexbox, Paper, TextField, Typography } from '@icr-ui/core'
import { useMediaQuery } from '@icr-ui/hooks'
import { ICRLogoIcon } from '@icr-ui/icons'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { updateUserPasswordSchema } from 'constant'
import { useResponseHandlers } from 'hooks'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useResetPasswordMutation } from 'store/api'
import { IResetPasswordRequest } from 'store/types'

type FormValues = {
  newPassword: string
  confirmNewPassword: string
}

const ResetPassword = () => {
  const isSmallScreen = useMediaQuery('(max-width: 883px)')
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(updateUserPasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  })
  const [newPassword, confirmNewPassword] = watch(['newPassword', 'confirmNewPassword'])
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  const [resetPassword, { isLoading, isError, status, error, isSuccess }] =
    useResetPasswordMutation()

  useResponseHandlers({
    isLoading,
    isSuccess,
    isError,
    error,
    successMessage: 'Passwort erfolgreich geändert',
    goBack: false,
  })

  const onSubmit = () => {
    const data: IResetPasswordRequest = {
      password: newPassword,
      token: token!,
    }
    resetPassword(data)
  }

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      const timer = setTimeout(() => {
        navigate(DefaultRoutes.LOGIN)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [status])

  return (
    <Flexbox padding="sm" fullScreen justifyContent="center">
      <Background />
      <Paper
        alignItems="center"
        justifyContent="space-between"
        width={isSmallScreen ? '88vw' : 400}
        padding={isSmallScreen ? 'sm' : 'md'}
        flexDirection="row"
        gap={isSmallScreen ? '32px' : '48px'}
        customStyles={{ zIndex: 1 }}
      >
        <Flexbox
          fullWidth
          noPadding
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          customStyles={{
            marginTop: isSmallScreen ? 0 : 16,
          }}
        >
          <ICRLogoIcon width={80} height={28.3} />
        </Flexbox>
        <Flexbox
          as="form"
          fullWidth
          noPadding
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="heading" size="h1" gutterBottom="32px">
            Neues Passwort eingeben
          </Typography>
          <Controller
            name="newPassword"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                id="newPassword"
                {...field}
                type="password"
                label="Neues Passwort"
                placeholder="********"
                showIconPassword
                isValid={isTouched && !error}
                messageType={error ? 'error' : undefined}
                helperText={errors.newPassword?.message}
                isRequired
              />
            )}
          />
          <Controller
            name="confirmNewPassword"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                id="confirmNewPassword"
                {...field}
                type="password"
                label="Neues Passwort bestätigen"
                placeholder="********"
                showIconPassword
                isValid={isTouched && !error}
                messageType={error ? 'error' : undefined}
                helperText={errors.confirmNewPassword?.message}
                isRequired
              />
            )}
          />
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            type="submit"
            disabled={!newPassword || !confirmNewPassword || isLoading}
            isLoading={isLoading}
            customStyles={{ alignSelf: 'center', margin: isSmallScreen ? 0 : '16px 0' }}
          >
            Neues Passwort speichern
          </Button>
        </Flexbox>
      </Paper>
    </Flexbox>
  )
}

export default ResetPassword
