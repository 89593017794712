import { ReactNode } from 'react'
import { useClickOutside } from '@icr-ui/hooks'

import { StyledList, StyledListItem, StyledWrapper } from './styles'

interface ListMenuProps {
  children: ReactNode
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  options: any
}

const ListMenu = ({ children, isOpen, setIsOpen, options }: ListMenuProps) => {
  const ref = useClickOutside(() => setIsOpen(false))

  return (
    <StyledWrapper ref={ref} isOpen={isOpen}>
      {children}
      <StyledList>
        {options.map((option: any) => (
          <StyledListItem key={option.id} onClick={() => option.callback(option)}>
            {option.label}
          </StyledListItem>
        ))}
      </StyledList>
    </StyledWrapper>
  )
}

export default ListMenu
