import { Col, Container, Row } from '@icr-ui/core'
import { SkeletonRowItem } from './styles'

const EditorSkeleton = () => {
  return (
    <>
      <Container fluid customStyles={{ paddingTop: '0 !important' }}>
        <Row alignItems="flex-start">
          <Col xs={12} sm={12} md={12} lg={12} noPadding>
            <SkeletonRowItem height="63.2vh" />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EditorSkeleton
