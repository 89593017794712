import { yupResolver } from '@hookform/resolvers/yup'
import { Background, Button, Flexbox, Paper, TextField, Typography } from '@icr-ui/core'
import { useMediaQuery } from '@icr-ui/hooks'
import { ICRLogoIcon } from '@icr-ui/icons'
import { forgotPasswordFormSchema } from 'constant'
import { useResponseHandlers } from 'hooks'

import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { DefaultRoutes } from 'routes/routes'
import { useForgotPasswordMutation } from 'store/api'
import { IForgotPasswordRequest } from 'store/types'

const ForgotPassword = () => {
  const isSmallScreen = useMediaQuery('(max-width: 883px)')
  const navigate = useNavigate()
  const currentUrl = window.location.host
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IForgotPasswordRequest>({
    mode: 'onBlur',
    resolver: yupResolver(forgotPasswordFormSchema),
    defaultValues: {
      username: '',
    },
  })
  const [username] = watch(['username'])

  const [forgotPassword, { isLoading, data, isError, error, isSuccess }] =
    useForgotPasswordMutation()

  const onSubmit = async () => {
    const dataPasswordForgot: IForgotPasswordRequest = {
      username: username,
      url: currentUrl + DefaultRoutes.RESET_PASSWORD + '?token=[token]',
    }
    forgotPassword(dataPasswordForgot)
  }

  const emailSendTo = data?.data?.emailSendTo ?? ''

  useResponseHandlers({
    isLoading,
    isSuccess,
    isError,
    error,
    successMessage: `Der Wiederherstellungslink wurde an die folgende E-Mail-Adresse gesendet ${emailSendTo}`,
    goBack: false,
  })

  return (
    <Flexbox padding="sm" fullScreen justifyContent="center">
      <Background />
      <Paper
        alignItems="center"
        justifyContent="space-between"
        width={isSmallScreen ? '88vw' : 400}
        padding={isSmallScreen ? 'sm' : 'md'}
        flexDirection="row"
        gap={isSmallScreen ? '32px' : '48px'}
        customStyles={{ zIndex: 1 }}
      >
        <Flexbox
          fullWidth
          noPadding
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ICRLogoIcon width={80} height={28.3} />
        </Flexbox>
        <Flexbox
          as="form"
          fullWidth
          noPadding
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flexbox
            noPadding
            fullWidth
            alignItems="flex-start"
            gap="16px"
            customStyles={{ marginBottom: 32 }}
          >
            <Typography variant="heading" size="h1">
              Passwort wiederherstellen
            </Typography>
            <Typography variant="body" size="normal">
              Sie wissen Ihr Passwort nicht mehr? Keine Sorge, wir helfen Ihnen, es
              wiederzufinden. Geben Sie unten Ihren Benutzernamen ein:
            </Typography>
          </Flexbox>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                id="username"
                {...field}
                type="text"
                label="Benutzername"
                placeholder="Benutzername"
                isValid={isTouched && !error}
                messageType={error ? 'error' : undefined}
                helperText={errors.username?.message}
                disabled={isLoading}
                isRequired
              />
            )}
          />
          <Flexbox
            noPadding
            fullWidth
            justifyContent="center"
            gap="24px"
            customStyles={{ marginTop: isSmallScreen ? 0 : 16 }}
          >
            <Button
              fullWidth={isSmallScreen}
              variant="text"
              type="button"
              onClick={() => navigate(-1)}
            >
              Zurück
            </Button>
            <Button
              fullWidth={isSmallScreen}
              variant="contained"
              type="submit"
              disabled={!username}
              isLoading={isLoading}
            >
              Passwort wiederherstellen
            </Button>
          </Flexbox>
        </Flexbox>
      </Paper>
    </Flexbox>
  )
}

export default ForgotPassword
