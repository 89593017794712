import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery, customBaseQuery } from './customFetchBase'

export const protectedApi = createApi({
  reducerPath: 'protectedApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'User',
    'PageList',
    'SectionList',
    'ComponentList',
    'GetContents',
    'Translation',
  ],
  endpoints: () => ({}),
})

export const publicAPI = createApi({
  reducerPath: 'publicAPI',
  baseQuery: baseQuery,
  endpoints: () => ({}),
})
